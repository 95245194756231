import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';


const OurTeam = () => {
    const [employees, setEmployees] = useState([]);

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await axios.get('https://www.admin.aedax-lawfirm.com/api/employees');
                if (Array.isArray(response.data.data)) {
                    setEmployees(response.data.data);
                } else {
                    console.error('Invalid response data format:', response.data);
                }
            } catch (error) {
                console.error('Error fetching employee data:', error.response?.data || error.message);
            }
        };

        fetchEmployees();
    }, []);


    return (
        <div className="relative justify-center items-center">
            <div className='py-8 bg-[#B7926E] mt-8'>
                <p className='text-center font-serif text-white text-4xl'>Tim Kami</p>
            </div>

            <div className="container mx-auto mt-8 grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mb-8 p-4 ">
                {employees.map(employee => (
                    <div key={employee.id} className='flex justify-center items-center'>
                        <div key={employee.id} className="bg-white md:p-4 p-2 rounded-lg shadow-lg flex flex-col items-center border-2 md:border-0 w-60">
                            <img
                                src={`https://www.admin.aedax-lawfirm.com/public/img/gallery/${employee.gambar}`}
                                alt=''
                                style={{ maxHeight: '200px', width: 'auto' }}
                                className='object-cover rounded-md mb-4'
                            />
                            <div className="text-center">
                                <h3 className="md:text-md text-sm mb-2 border-2 border-black bg-white -mt-10 font-serif p-1">{employee.Nama}</h3>
                                <button className='bg-[#00285D] px-8 py-1 rounded-lg mt-2'>
                                    <Link to={`/timkami/${employee.id}`} className="text-white hover:underline font-serif">
                                        Profil
                                    </Link>
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default OurTeam;
