import React, { useEffect, useState } from 'react';
import Home from '../components/Home'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const Beranda = () => {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <div className={`fixed top-0 left-0 w-full z-50 transition-all ${scrolling ? 'bg-white shadow-lg' : 'bg-transparent'}`}>
        <Navbar />
      </div>
      <div className='mt-28 md:mt-36'>
        <Home />
      </div>
      <Footer />
    </div>
  )
}

export default Beranda