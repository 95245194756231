import React from 'react';
import { Link } from 'react-router-dom';
import logo from './assets/navbar/logo.png';

const Navbar = () => {

  return (
    <div className={`p-2 flex items-center border-b-2`}>
      <div className='justify-start md:ml-56 ml-4'>
        <img src={logo} alt="" width={105} />
      </div>
      <div className="md:absolute md:ml-4 ml-4 font-serif end-0 md:mr-56 md:text-lg text-sm">
        <Link to="/" className="mr-4">
          <button className="text-black decoration-[#B7926E] hover:underline focus:underline hover:border-b-2 focus:border-b-2 border-transparent">
            Beranda
          </button>
        </Link>
        <Link to="/tentangkami" className="mr-4">
          <button className="text-black decoration-[#B7926E] hover:underline focus:underline hover:border-b-2 focus:border-b-2 border-transparent">
            Tentang Kami
          </button>
        </Link>
        <Link to="/timkami" className="mr-4">
          <button className="text-black decoration-[#B7926E] hover:underline focus:underline hover:border-b-2 focus:border-b-2 border-transparent">
            Tim Kami
          </button>
        </Link>
        <Link to="/layanan" className="mr-4">
          <button className="text-black decoration-[#B7926E] hover:underline focus:underline hover:border-b-2 focus:border-b-2 border-transparent">
            Layanan
          </button>
        </Link>
        <Link to="/hubungikami">
          <button className={`bg-[#00285D] text-white md:py-2 px-4 items-center border-2 self-center -mt-4`}>Hubungi Kami</button>
        </Link>
      </div>
    </div>
  );
}

export default Navbar;