import React, { useState, useEffect } from 'react';

const MesinKetik = ({ teks, tertunda }) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentIndex < teks.length) {
        setCurrentText((prevText) => prevText + teks[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else {
        // If the end of the text is reached, reset the animation
        setCurrentText('');
        setCurrentIndex(0);
      }
    }, tertunda);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [currentIndex, tertunda, teks]);

  return <span>{currentText}</span>;
};

export default MesinKetik;
