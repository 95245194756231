import React from 'react';
import cardLayanan from '../assets/layanan/cardlayanan.png'
import { Link } from 'react-router-dom';


const ServiceCard2 = ({ title, image, link }) => {
    return (
        <div className='flex-grow justify-center md:mt-20 mt-8 mx-auto md:px-10 w-36 md:w-full'>
            <div className="bg-white border-2 border-black md:px-10 px-3 md:py-5 py-2 rounded-t-xl shadow-md md:h-48 h-36" >
                <div className='w-8 md:w-10 md:-ml-6'>
                    <img src={cardLayanan} alt="" />
                </div>
                <div className='flex justify-center items-center'>
                    <img src={image} alt="" className="md:w-[78px] w-14 h-18 md:h-20 mb-2 mt-2 items-center" />
                </div>
            </div>
            <div className='py-2 bg-[#B7926E] text-center rounded-b-xl font-serif text-white border-x-2 border-b-2 border-black md:h-28 flex flex-col justify-center'>
                <div className=''>
                    <p className="md:text-lg text-sm font-serif mb-2 text-white text-center h-10 md:h-14">{title}</p>
                </div>
                <button>
                    <Link to={link} className='text-xs underline decoration-white'>SELENGKAPNYA</Link>
                </button> 

            </div>
        </div>
    );
};

export default ServiceCard2;
