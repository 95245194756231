import React from 'react'
import Navbar from '../components/Navbar.jsx'
import OurTeam from '../components/OurTeamDetails.jsx'
import Footer from '../components/Footer.jsx'


const TimKami = () => {
    return (
        <>
            <div>
                <div className='fixed top-0 left-0 w-full z-50 bg-white'>
                    <Navbar />
                </div>
                <div className='mt-36'>
                    <OurTeam />
                </div>
                <Footer />
            </div>
        </>
    )
}

export default TimKami