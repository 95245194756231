import React from 'react';

function MapViewHp() {
  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3989.318694515229!2d100.35183307496506!3d-0.9067509990844733!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMMKwNTQnMjQuMyJTIDEwMMKwMjEnMTUuOSJF!5e0!3m2!1sid!2sid!4v1709026814819!5m2!1sid!2sid"
        width={400}
        height={250}
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Maps Aedax LawFirm"
      ></iframe>
    </div>
  );
}

export default MapViewHp;
