import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const OurTeamDetails = () => {
    const [employee, setEmployee] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchEmployeeData = async () => {
            try {
                const response = await axios.get(`https://www.admin.aedax-lawfirm.com/api/employees/${id}`);
                setEmployee(response.data.data);
            } catch (error) {
                console.error('Error fetching employee data:', error.response?.data || error.message);
            }
        };

        fetchEmployeeData();
    }, [id]);

    if (!employee) {
        return <div className='text-center font-serif text-4xl text-bold h-screen flex justify-center items-center underline text-[#B7926E]'>Data Tidak Ada!</div>; // Menampilkan pesan "Loading" selama data diambil.
    }

    return (
        <div className="container mx-auto mt-8 mb-8">
            <h2 className="text-3xl mb-4 text-center font-serif underline decoration-[#B7926E] ">Our Tim's Profile {employee.id}</h2>
            <div className='md:hidden block flex justify-center md:p-0 p-10 '>
                <img
                    src={`https://www.admin.aedax-lawfirm.com/public/img/gallery/${employee.gambar}`}
                    alt={employee.Nama}
                    className="md:hidden block w-[200px] object-cover rounded-md"
                />
            </div>
            <div className="bg-white md:p-32 rounded-lg shadow-2xl md:flex justify-center mx-auto md:space-x-72 border-2">

                <div className='items-center justify-center'>
                    <p className='md:text-lg text-center md:text-start  p-4  md:p-2 font-serif'>Nama: {employee.Nama}</p>
                    <p className='md:text-lg text-center md:text-start  p-4  md:p-2 font-serif flex'>Alamat:  {employee.Alamat}</p>
                    <p className='md:text-lg text-center md:text-start  p-4  md:p-2 font-serif'>Tempat dan Tanggal Lahir: {employee.TTL}</p>
                    <p className='md:text-lg text-center md:text-start  p-4  md:p-2 font-serif'>No. Kartu Tanda Angota: {employee.ID_Numb_Membership}</p>
                    <p className='md:text-lg text-center md:text-start  p-4  md:p-2 font-serif'>No. Berita Acara Sumpah: {employee.ID_Numb_Oath}</p>
                    <p className='md:text-lg text-center md:text-start  p-4  md:p-2 font-serif'>Bergabung Sejak: {employee.bergabung_sejak}</p>
                </div>
                <div className='hidden md:block flex justify-center md:p-2'>
                    <img
                        src={`https://www.admin.aedax-lawfirm.com/public/img/gallery/${employee.gambar}`}
                        style={{ maxHeight: '250px', width: 'auto' }}
                        alt={employee.nama}
                        className="w-[200px] object-cover rounded-md"
                    />
                </div>

            </div>
        </div>
    );
};

export default OurTeamDetails;
