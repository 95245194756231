import { Route, Routes } from 'react-router-dom';
import Formulir from './pages/Formulir';
import Beranda from './pages/Beranda';
import TentangKami from './pages/TentangKami';
import TimKamiDetails from './pages/TimKamiDetails';
import TimKami from './pages/TimKami';
import Layanan from './pages/Layanan';
import "aos/dist/aos.css";
import LayananHukum from './pages/LayananHukum';
import LayananHukum2 from './pages/LayananHukum2';
import LayananHukum3 from './pages/LayananHukum3';
import LayananHukum4 from './pages/LayananHukum4';


function App() {
  // useEffect(() => {
  //   Aos.init({
  //     duration: 2000,
  //     offset: 100,
  //     disable: "mobile",
  //   });
  // }, []);
  
  return (
    <div className="App">
      <Routes>
      <Route path="/" element = {<Beranda/>}/>
      <Route path = "/hubungikami" element = {<Formulir/>}/>
      <Route path = "/timkami/:id" element = {<TimKamiDetails/>}/>
      <Route path = "/timkami" element = {<TimKami/>}/>
      <Route path = "/layanan" element = {<Layanan/>}/>
      <Route path = "/tentangkami" element = {<TentangKami/>}/>
      <Route path = "/layanan/aktivitashukum" element = {<LayananHukum/>}/> \      
      <Route path = "/layanan/konsultanpajak" element = {<LayananHukum2/>}/> \      
      <Route path = "/layanan/kekayaanintelektual" element = {<LayananHukum3/>}/> \      
      <Route path = "/layanan/konsultanhukum" element = {<LayananHukum4/>}/> \      
      
      </Routes>
    </div>
  );
}

export default App;
