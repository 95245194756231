import React from 'react'
import Navbar from '../components/Navbar'
import Tentangkami from '../components/AboutUs'
import Footer from '../components/Footer'

const Tentang_Kami = () => {
  return (
    <>
    <div>
        <div className='fixed top-0 left-0 w-full z-50 bg-white'>
          <Navbar />
        </div> 
        <div className='mt-28 md:mt-36'>
            <Tentangkami/>
        </div> 
        <Footer/>
    </div>
    </>
    )
}

export default Tentang_Kami