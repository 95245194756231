import React, { useState } from 'react';
import tentangkami from './assets/tentangkami/tentangkami.svg';
import tentangkami2 from './assets/tentangkami/foto_profil.png';
import tentangkami3 from './assets/tentangkami/tentangkami3.svg';
import tentangkami4 from './assets/tentangkami/tentangkami4.svg';
import tentangkami5 from './assets/tentangkami/tentangkami5.svg';
import tentangkami6 from './assets/tentangkami/tentangkami6.svg';
import tentangkami7 from './assets/tentangkami/tentangkami7.svg';
import arrowtentangkami from './assets/tentangkami/arrowtentangkami.png';
import ModalVisi from './assets/ModalVisi';
import logovisi from './assets/tentangkami/logovisi.png';
import logomisi from './assets/tentangkami/logomisi.png';
import ModalMisi from './assets/ModalMisi';
import alurpelayanan1 from './assets/tentangkami/alurpelayanan1.svg';
import alurpelayanan2 from './assets/tentangkami/alurpelayanan2.svg';
import alurpelayanan3 from './assets/tentangkami/alurpelayanan3.svg';
import panah from './assets/tentangkami/arrowalurpelayanan.svg';



const AboutUs = () => {
    const [modalOpenVisi, setModalOpenVisi] = useState(false);

    const openModalVisi = (logo, title, content) => {
        setModalOpenVisi({ isOpen: true, logo, title, content });
    };

    const closeModalVisi = () => {
        setModalOpenVisi(false);
    };
    const [modalOpenMisi, setModalOpenMisi] = useState(false);

    const openModalMisi = (logo, title, content) => {
        setModalOpenMisi({ isOpen: true, logo, title, content });
    };

    const closeModalMisi = () => {
        setModalOpenMisi(false);
    };

    const visiContent = `
    “Terwujudnya penegakan hukum yang berisikan nilai kebenaran, keadilan, dan kemanusiaan, 
    melalui pemberdayaan masyarakat dalam setiap pelaksanaan kegiatan bantuan hukum yang berpihak 
    pada kaum lemah, miskin, tertindas dan terpinggirkan”.`;

    const misiContent = `
    Membantu (secara langsung atau aktif) masyarakat (klien) dalam menyelesaikan permasalahan hukum yang dihadapinya.
    Memberikan gambaran, penjelasan dan pemikiran/pendapat pada masyarakat (klien) untuk mengatasi permasalahan hukum yang dihadapinya.
    Melakukan advokasi pada pencari keadilan secara litigasi dan non litigasi, baik dalam perkara individual maupun struktural.
    Menerapkan kaidah/prinsip kekeluargaan dalam rangka peningkatan pelayanan bantuan hukum kepada masyarakat.
    Mengutamakan pengabdian dan mengesampingkan tujuan yang bersifat komersil.`;




    return (
        <div className='mx-auto'>
            <div className='md:h-screen items-center text-center justify-center  '>
                <img src={tentangkami} alt="" />
                <p className='relative -mt-24 md:-mt-96 underline text-white text-center justify-center font-[Poppins] font-semibold md:text-4xl text-2xl'>Tentang Kami</p>
            </div>
            <div className='md:p-10 p-4 md:h-screen items-center justify-center md:flex'>
                <div className='md:mx-44 border-4 border-[#B7926E] rounded-lg items-center md:items-start mt-32 md:mt-0 p-3'>
                    <div className='md:p-3 flex justify-center md:justify-start'>
                        <p className='underline decoration-[#B7926E] text-center font-serif md:text-3xl text-3xl font-normal md:ml-4'>Filosofi</p>
                    </div>
                    <div className='md:flex w-full justify-center items-center mt-4 md:-mt-14'>
                        <div className='p-2 w-full md:w-2/3 items-center'>
                            <p className='items-center text-center md:text-start md:ml-4'>
                                Aequus Et Audax (Aedax) Lawfirm, didirikan pada tahun 2023, adalah kantor hukum yang berkarakter integritas tinggi,
                                dinamis, bersemangat, dan selalu berada di garis depan mengikuti setiap perkembangan hukum di Indonesia.
                                Kami menawarkan pelayanan hukum yang optimal di berbagai bidang, termasuk pidana, perdata, kepailitan, perusahaan, perbankan, kontrak, dan kekayaan intelektual.
                                Pemenuhan kebenaran dan keadilan menjadi komitmen utama kami, tercermin dalam setiap konsultasi yang kami berikan sebagai bukti kepercayaan dan penghargaan terhadap hukum sebagai panduan dalam kehidupan.
                            </p>
                        </div>
                        <div className='p-2 md:w-1/3 flex flex-col items-center justify-center'>
                            <img className='md:w-52 w-36' src={tentangkami2} alt="" />
                            <p className='text-center font-serif'>Sarminal, S.H., M.Hum</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='md:flex p-4 items-center h-screen '>
                <div className='md:w-1/2'>
                    <div className='flex items-end w-fit mx-auto mt-4 md:mt-0'>
                        <div className='relative md:w-52 w-40 items-center justify-items-center mx-auto'>
                            <img src={tentangkami3} alt="" />
                            <div className='absolute md:-mt-36 -mt-32 text-center left-1/2 transform -translate-x-1/2'>
                                <p className='text-[#D8C4B6] md:text-xl text-lg font-serif'>Visi</p>
                                <div className='flex items-center justify-center'>
                                    <button className='bg-[#B7926E] text-white px-7 py-2 md:py-1 mt-5 rounded-lg flex font-serif text-sm md:text-lg'
                                        onClick={() => openModalVisi(logovisi, 'Visi', visiContent)}
                                    >Selengkapnya
                                        <img src={arrowtentangkami} alt="" className=' md:h-7 md:w-7 h-5 w-5' />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='md:w-56 w-44 '>
                            <img src={tentangkami4} alt="" />
                        </div>
                    </div>
                    <div className='flex mx-auto w-fit '>
                        <div className='md:w-56 w-44 '>
                            <img src={tentangkami6} alt="" />
                        </div>
                        <div className=' md:w-52 w-40 items-center justify-center mx-auto  relative'>
                            <img src={tentangkami5} alt="" />
                            <div className='absolute md:-mt-36 -mt-32 text-center left-1/2 transform -translate-x-1/2'>
                                <p className='text-[#00285D] text-xl font-serif'>Misi</p>
                                <div className='flex items-center justify-center'>
                                    <button className='bg-[#00285D] text-white px-7 py-2 md:py-1 mt-5 rounded-lg flex font-serif text-sm md:text-lg'
                                        onClick={() => openModalMisi(logomisi, 'Misi', misiContent)}
                                    >Selengkapnya
                                        <img src={arrowtentangkami} alt="" className='md:h-7 md:w-7 h-5 w-5' />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalVisi
                    isOpen={modalOpenVisi.isOpen}
                    onClose={closeModalVisi}
                    logo={modalOpenVisi.logo}
                    title={modalOpenVisi.title}
                    content={modalOpenVisi.content}
                />
                <ModalMisi
                    isOpen={modalOpenMisi.isOpen}
                    onClose={closeModalMisi}
                    logo={modalOpenMisi.logo}
                    title={modalOpenMisi.title}
                    content={modalOpenMisi.content}
                />
                <div className='md:w-1/2 mx-auto mt-4 md:mt-0'>
                    <div className='flex justify-center'>
                        <p className='font-[prozalibre] mt-4 text-xl md:text-3xl md:w-1/2 md:text-start text-[#00285D] text-center'>
                            Keadilan Dengan Keberanian: Aedax Law Firm, Pilihan  Solusi Hukum Terpercaya.
                        </p>
                    </div>
                    <div className='flex justify-center'>
                        <div className='md:w-1/2 mt-4'>
                            <p className='font-serif md:text-start text-center text-md'>
                                Aedax Lawfirm lahir dari aspirasi mendalam untuk membentuk sebuah platform pelayanan hukum yang inovatif,
                                energik, dan terkini. Tim Advokat, staf, dan sistem manajemen kami yang superior mendedikasikan diri untuk
                                menjaga integritas, memberikan layanan dengan profesionalisme tinggi, dan menanggung tanggung jawab penuh.
                                Kami berkomitmen untuk memberikan pengalaman pelayanan hukum terbaik kepada klien kami, dengan fokus pada
                                keunggulan dan kepercayaan.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='md:-mt-72 mt-32'>
                <img src={tentangkami7} alt="" width={1512} />
            </div>
            <div className='md:h-screen mt-8'>
                <div className='md:flex justify-center text-center items-center'>
                    <p className='font-serif text-xl md:text-3xl text-[#B7926E]'>ALUR PELAYANAN</p>
                </div>
                <div className='md:flex justify-center items-center mt-14 md:w-5/6 mx-auto'>
                    <div className='md:flex md:flex-col items-center mx-auto md:px-14 md:py-0 px-10 py-8'>
                        <div className='md:flex items-center justify-center'>
                            <img className='mx-auto' src={alurpelayanan1} alt="" width={100} />
                        </div>
                        <p className='mt-4 md:mt-2 md:w-3/4 md:py-5 font-serif text-[#868686] text-center md:text-start'>Klien menghubungi Aedax Lawfirm untuk mendiskusikan permasalahan hukum, Aedax mengatur pertemuan konsultasi awal dengan klien untuk mengevaluasi kasus.</p>
                    </div>
                    <img className='-mt-64 hidden md:block' src={panah} alt="" width={80} />
                    <div className='flex flex-col items-center mx-auto md:px-10 md:py-0 px-10 py-8'>
                        <div className='flex items-center justify-center' >
                            <img className='mx-auto' src={alurpelayanan2} alt="" width={100} />
                        </div>
                        <p className='mt-4 md:mt-2 md:text-start md:w-2/3 md:p-2 font-serif text-[#868686] text-center'>Setelah klien menyetujui kerjasama, Aedax melakukan penelitian mendalam terkait hukum yang berkaitan dengan kasus, merinci strategi hukum yang akan diambil, termasuk kemungkinan langkah-langkah penyelesaian alternatif.</p>
                    </div>
                    <img className='-mt-64 hidden md:block' src={panah} alt="" width={80} />
                    <div className='flex flex-col items-center mx-auto md:px-2 md:py-0 px-10 py-8'>
                        <div className='justify-center flex' >
                            <img className='mx-auto' src={alurpelayanan3} alt="" width={100} />
                        </div>
                        <p className='mt-4 md:mt-2 md:text-start md:w-2/3 md:p-2 font-serif text-[#868686] text-center'>Aedax Lawfirm memulai tindakan hukum sesuai dengan rencana yang telah disusun. Selama proses ini, firma memberikan pembaruan berkala kepada klien mengenai perkembangan kasus dan mengatasi perubahan yang mungkin terjadi.</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AboutUs