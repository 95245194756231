import React from 'react';
import { Link } from 'react-router-dom';


const ServiceCard = ({ title, image, link }) => {
    return (
        <div className='flex-grow justify-center md:mt-20 mt-8 mx-auto md:px-10 w-36 md:w-full'>
            <div className="bg-[#E4DACD] md:px-10 px-3 md:py-5 py-2 rounded-md shadow-md md:h-full h-52"  >
                <div className='flex justify-center items-center '>
                    <img src={image} alt="" className="md:w-20 w-14 h-14 md:h-20 mb-2 mt-2 items-center " />
                </div>
                <div className='mt-5'>
                    <p className="md:text-2xl text-lg font-serif mb-2 text-[#00285D] text-center">{title}</p>
                </div>
            </div>
            <div className='py-2 bg-[#B7926E] text-center rounded-b-lg text-sm md:text-md font-serif text-white' >
                <button> 
                    <Link to={link} className='underline decoration-white'>SELENGKAPNYA </Link>
                </button>
            </div>
        </div>
    );
};

export default ServiceCard;
