import React from 'react';
import layanan from './assets/layanan/layanan.svg';
import ServiceCard2 from './assets/ServiceCard2';
import layanan1 from './assets/layanan/layanan1.png';
import layanan2 from './assets/layanan/layanan2.png';
import layanan3 from './assets/layanan/layanan3.png';
import layanan4 from './assets/layanan/layanan4.png';



const Service = () => {
  return (
    <div className='mx-auto md:h-screen'>
      <div className='text-center text-3xl md:text-4xl font-serif mt-8'>
        <p>Layanan Hukum</p>
      </div>
      <div className='flex justify-center'>
        <div className='md:block hidden mt-2'>
          <img src={layanan} alt="" width={400} />
        </div>
      </div>
      <div className='justify-center flex block md:hidden mt-2'>
        <img src={layanan} alt="" width={300} />
      </div>
      <div className='flex justify-center'>
        <div className='w-4/6 mt-8 flex justify-center items-center'>
          <p className='p-2 text-black text-center font-serif'>
            Aedax Law Firm mempunyai izin sebagai Konsultan Hukum Pajak, Kuasa Aktivitas hukum & Kuasa Hukum Kekayaan Intelektual sehingga mempunyai kualifikasi yang tepat untuk menangani permasalahan hukum kepailitan, hukum perbankan, surat wasiat, pengajuan dan pengurusan dibidang kekayaan intelektual seperti yang kami uraikan di bawah.
          </p>
        </div>
      </div>
      <div className="container mx-auto mt-8 md:w-[1150px] grid gap-2 grid-cols-2 md:grid-cols-2 lg:grid-cols-4 md:mb-0 mb-10 ">
        {/* Service Cards */}
        <ServiceCard2
          title="Aktivitas Hukum"
          image={layanan1}
          link="/layanan/aktivitashukum"
        />
        <ServiceCard2
          title="Aktivitas Konsultan  Hukum"
          image={layanan2}
          link="/layanan/konsultanhukum"
        />
        <ServiceCard2
          title="Aktivitas Kekayaan Intelektual "
          image={layanan3}
          link="/layanan/kekayaanintelektual"
        />
        <ServiceCard2
          title="Aktivitas Konsultan Pajak"
          image={layanan4}
          link="/layanan/konsultanpajak"
        />
      </div>
    </div>
  )
}

export default Service;
