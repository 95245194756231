import React from 'react';
import { Link } from 'react-router-dom';
import wa from './assets/footer/cellphone.svg';
import email from './assets/footer/email.svg';
import footer from './assets/footer/footer.svg';
import MapView from './assets/MapView';
import MapViewHp from './assets/MapViewHp';
import vector from './assets/footer/Vector.png';


const Footer = () => {
    const footerStyle = {
        backgroundImage: `url(${footer})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    };

    return (
        <div className='text-white mx-auto'>
            <div className='relative z-10 w-full' style={footerStyle}>
                <div className='flex md:justify-start justify-center md:ml-[170px] text-2xl md:text-3xl '>
                    <p className='mt-10 md:w-1/4 w-3/4 justify-center text-center font-serif'>
                        Aequus Et Audax (AEDAX) Law Firm
                    </p>
                </div>
                <div className='w-fit mx-auto mt-10 items-center flex p-4 block md:hidden '>
                    <img className= '-mt-6 'src={vector} width={15} alt="" />
                    <p className='text-center md:text-md text-md mx-auto'> Buka Pukul 09.00 Wib - Jln. Jhoni Anwar Gg. Atlas I No.9 - Padang.</p>

                </div>
                <div className='w-fit mx-auto mt-8 -mb-10 md:hidden block'>
                    <MapViewHp />
                </div>

                <div className=' md:w-3/4 md:ml-48 mt-10 md:flex text-center md:text-start p-3'>
                    <div className='m-2 hidden md:block'>
                        <img src={vector} width={65} alt="" />
                    </div>
                    <p className='w-full mt-1 hidden md:block text-lg'>
                        Aedax LawFirm Buka Pukul 09.00 Wib -
                        Jln. Jhoni Anwar Gg. Atlas I No.9 - Padang.
                    </p>
                    <div className='md:text-start w-full md:ml-52 font-serif text-xl items-center mt-10 md:mt-0 '>
                        <div className=''>
                            <Link to="/">Beranda</Link>
                        </div>
                        <div className='mt-1'>
                            <Link to="/tentangkami">Tentang Kami</Link>
                        </div>
                        <div className='mt-1'>
                            <Link to="/timkami">Tim Kami</Link>
                        </div>
                        <div className='mt-1'>
                            <Link to="/">Layanan</Link>
                        </div>
                        <div className='mt-1'>
                            <Link to="/hubungikami">Hubungi Kami</Link>
                        </div>
                    </div>
                    <div className='font-serif w-full mt-10 md:mt-0'>
                        <p className='text-xl'>Hubungi Kami</p>
                        <div className='flex items-center justify-center  md:justify-start'>
                            <img className='mt-2 ' src={wa} alt="" width={30} />
                            <span className='p-2 mt-2'>+6285186856608</span>
                        </div>
                        <div className='flex items-center justify-center  md:justify-start'>
                            <img className='mt-2 ' src={email} alt="" width={30} />
                            <span className='p-2 mt-2'>aedaxlawfirm@gmail.com</span>
                        </div>
                    </div>
                </div>
                <div className='md:ml-48 mt-10 border-2 w-fit hidden md:block '>
                    <MapView />
                </div>
                <div className='border-t-2 p-2 mt-10 text-center font-[poppins] font-semibold '>
                    <p> © Copyright Aedax Law Firm 2024 </p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
