import React from 'react';
import closemisi from './tentangkami/closemisi.svg';

const ModalMisi = ({ isOpen, onClose, logo, title, content }) => {
  if (!isOpen) return null;

  const misiContentWithNumbers = content.split('\n').map((paragraph, index) => {
    if (paragraph.trim() !== "") {
      return (
        <p key={index + 0} className="text-center md:text-start md:p-0 p-1 font-serif md:text-lg text-sm text-[#00285D]">
          {index + 0}. {paragraph}
        </p>
      );
    }
    return null;
  });

  return (
    <div className="p-4 md:p-0 md:mt-32 mt-16 fixed inset-0 flex items-center justify-center md:w-1/2">
      <div className="rounded-xl bg-[#B7926ED9] p-8 w-[515px] md:h-6/7 flex flex-col items-center relative">
        <button
          className="absolute top-4 right-8"
          onClick={onClose}>
          <img src={closemisi} alt="" width={30} />
        </button>
        <div className="flex justify-center items-center md:space-x-2">
          <img className="items-center" src={logo} alt="" width={50} />
          <p className="text-3xl text-center text-[#00285D] font-serif">{title}</p>
        </div>
        
        {misiContentWithNumbers}
      </div>
    </div>
  );
};

export default ModalMisi;
