import React from 'react';
import beranda from './assets/beranda/beranda.svg';
import foto_profil from './assets/beranda/foto_profil.png';
import beranda4 from './assets/beranda/beranda4.svg';
import beranda5 from './assets/beranda/beranda5.png';
import beranda6 from './assets/beranda/beranda6.png';
import beranda7 from './assets/beranda/beranda7.png';
import beranda8 from './assets/beranda/beranda8.png';
import MesinKetik from './assets/MesinKetik';
import ServiceCard from './assets/SeviceCard';

const Home = () => {

  return (
    <div className='items-center justify-center relative '>
      <div className='relative backdrop-filter backdrop-blur-md opacity-100'>
        <div className='relative md:h-screen '>
          <img
            className='  transition-opacity duration-300'
            src={beranda}
            alt=""
            width={1512}
          />
          <div className='absolute md:inset-52 justify-center text-center text-white md:text-start md:w-[450px] md:-mt-10 -mt-36'  >
            <p className='text-lg md:text-4xl font-serif'>
              <MesinKetik teks=' "Aequus Et Audax - Keadilan dan Pemberani" ' tertunda={150} />
            </p>
            <p className='text-lg md:text-lg mt-4 font-serif'>
              Keadilan sebagai dasar perlakuan setara, sementara keberanian menjadi kekuatan menghadapi tantangan.
            </p>
          </div>
        </div>
        <div className='flex md:flex-row w-full justify-center md:justify-end z-10 md:-mt-72 md:relative md:-ml-32 mt-4'>
          <div className='md:p-7 md:w-1/6 w-full bg-[#D8C4B6] p-3'>
            <p className='font-serif md:text-md text-sm'>
              Aequus- keadilan, menjadi pondasi kuat yang mewarnai setiap aspek praktik hukum kami.
            </p>
          </div>
          <div className='md:p-8 md:w-1/6 w-full bg-[#B7926E] p-4'>
            <p className='font-serif md:text-md text-sm'>Audax- keberanian dalam menghadapi kompleksitas hukum.</p>
          </div>
        </div>
        <div className='md:flex mt-4 md:h-screen justify-center items-center'>
          <div className='md:px-64 items-center justify-center flex'>
            <img className='md:w-[600px] w-40 ' src={foto_profil} alt="" />
          </div>
          <div className='flex flex-col items-center justify-center md:items-start md:justify-start md:mt-0 '>
            <p className='text-[#B7926E] text-2xl md:text-4xl md:mt-14 mt-8 font-serif text-center md:text-left'>
              Selamat Datang
            </p>
            <p className='text-black mt-4 md:w-2/3 text-lg font-[poppins] md:text-md text-center md:text-left p-3 md:p-0'>
              di Aedax Law Firm, tempat di mana keadilan dan keberanian bertemu.
              Kami adalah kumpulan para ahli hukum yang berkomitmen untuk menyediakan
              pelayanan hukum terbaik dengan prinsip adil dan berani.
            </p>
            <div className='flex justify-center md:justify-start mt-8'>
              <button className='px-14 py-2 bg-[#B7926E] text-white underline font-serif text-sm md:text-md'>SELENGKAPNYA</button>
            </div>
          </div>
        </div>
        <div className='md:-mt-36'>
          <img src={beranda4} alt="" />
        </div>
      </div>
      <div className='md:h-screen'>
        <div className='flex justify-center mt-20 font-serif text-[#B7926E] text-2xl md:text-4xl'>
          <p>LAYANAN KAMI</p>
        </div>
        <div className="container  mx-auto mt-8 md:w-[1150px] grid gap-2 grid-cols-2 md:grid-cols-2 lg:grid-cols-4 md:mb-0 mb-10 ">
          {/* Service Cards */}
          <ServiceCard
            title="Aktivitas Hukum"
            image={beranda5}
            link="/layanan/aktivitashukum"
          />
          <ServiceCard
            title="Aktivitas Konsultan  Hukum"
            image={beranda6}
            link="/layanan/konsultanhukum"
          />
          <ServiceCard
            title="Aktivitas Kekayaan Intelektual "
            image={beranda7}
            link="/layanan/kekayaanintelektual"
          />
          <ServiceCard
            title="Aktivitas Konsultan Pajak"
            image={beranda8}
            link="/layanan/konsultanpajak"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
