import React from 'react';
import layanan from './layanan/layanan.svg';
import layananaktifitas from './layanan/layananaktifitas.png';
import { content } from "./content";


const AktivitasHukum = () => {
  const { AktivitasHukum } = content;
  return (
    <div className='mx-auto p-3 md:p-0'>
      <div className='text-center text-3xl md:text-4xl font-serif mt-8'>
        <p>{AktivitasHukum.title}</p>
      </div>
      <div className='flex justify-center'>
        <div className='md:block hidden mt-2'>
          <img src={layanan} alt="" width={400} />
        </div>
      </div>
      <div className='justify-center flex block md:hidden mt-2'>
        <img src={layanan} alt="" width={300} />
      </div>
      <div className='md:flex justify-center mt-12'>
        <div className='md:flex'>
          <img className='w-[340px] h-[200px]' src={layananaktifitas} alt="" />
        </div>
      <div className='text-start md:text-center mt-4 md:ml-10 p-10 md:p-0'>
        <p className='text-lg font-serif'>{AktivitasHukum.subtitle}</p>
        <p className='text-start mt-4 font-serif'>{AktivitasHukum.body1}</p>
        <p className='text-start font-serif'>{AktivitasHukum.body2}</p>
        <p className='text-start font-serif'>{AktivitasHukum.body3}</p>
        <p className='text-start font-serif'>{AktivitasHukum.body4}</p>
        <p className='text-start font-serif'>{AktivitasHukum.body5}</p>
        <p className='text-start font-serif'>{AktivitasHukum.body6}</p>
        <p className='text-start font-serif'>{AktivitasHukum.body7}</p>
        <p className='text-start font-serif'>{AktivitasHukum.body8}</p>
        <p className='text-start font-serif'>{AktivitasHukum.body9}</p>

        
      </div>
      </div>
    </div>
  )
}

export default AktivitasHukum;
