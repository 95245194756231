import React from 'react';
import layanan from './layanan/layanan.svg';
import layananaktifitas from './layanan/layananaktifitas.png';
import { content } from "./content";


const AktivitasKonsultanPajak = () => {
    const { AktivitasKonsultanPajak } = content;
    return (
      <div className='mx-auto p-3 md:p-0'>
        <div className='text-center text-3xl md:text-4xl font-serif mt-8'>
          <p>{AktivitasKonsultanPajak.title}</p>
        </div>
        <div className='flex justify-center'>
          <div className='md:block hidden mt-2'>
            <img src={layanan} alt="" width={400} />
          </div>
        </div>
        <div className='justify-center flex block md:hidden mt-2'>
          <img src={layanan} alt="" width={300} />
        </div>
        <div className='md:flex justify-center mt-12'>
          <div className='md:flex'>
            <img className='md:w-[340px] md:h-[200px]' src={layananaktifitas} alt="" />
          </div>
        <div className='text-center md:text-start mt-4 md:ml-10 md:w-1/3 p-10 md:p-0'>
          <p className='text-lg font-serif'>{AktivitasKonsultanPajak.subtitle}</p>
          <p className='text-center md:text-start mt-4 font-serif '>{AktivitasKonsultanPajak.body1}</p>  
        </div>
        </div>
      </div>
    )
  }

export default AktivitasKonsultanPajak