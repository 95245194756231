import React from 'react';
import closevisi from './tentangkami/closevisi.svg';

const ModalVisi = ({ isOpen, onClose, logo, title, content }) => {
  if (!isOpen) return null;

  return (
    <div className="p-4 md:p-0 md:mt-32 fixed inset-0 flex items-center justify-center md:w-1/2">
      <div className="rounded-xl bg-[#00285DD9] p-8 w-[515px] md:h-5/6  flex flex-col items-center relative">
        <button
          className="absolute top-4 right-8"
          onClick={onClose}>
          <img src={closevisi} alt="" width={30} />
        </button>
        <div className="flex justify-center items-center md:space-x-2">
          <img className="items-center" src={logo} alt="" width={50} />
          <p className="text-3xl text-center text-[#B7926E] font-serif">{title}</p>
        </div>
        <p className="text-center md:text-start md:p-6 p-5  font-serif text-lg text-[#B7926E]">{content}</p>
      </div>
    </div>
  );
};

export default ModalVisi;
