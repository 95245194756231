import React, { useState } from 'react';
import axios from 'axios';
import line from './assets/formulir/line.svg';
import dropdownArrow from './assets/formulir/dropdown.svg';
import { useNavigate } from "react-router-dom";

const Form = () => {
  const [nama, setNama] = useState('');
  const [email, setEmail] = useState('');
  const [no_telepon, setNo_telepon] = useState('');
  const [layanan, setLayanan] = useState('');
  const [bidang, setBidang] = useState('');
  const [kronologi, setKronologi] = useState('');
  const [namaError, setNamaError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [noTeleponError, setNoTeleponError] = useState('');
  const [layananError, setLayananError] = useState('');
  const [bidangError, setBidangError] = useState('');
  const [kronologiError, setKronologiError] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [bidangOptions, setBidangOptions] = useState([]);
  const navigate = useNavigate();

  const showConfirmationPrompt = () => {
    setShowConfirmation(true);
  };

  const hideConfirmationPrompt = () => {
    setShowConfirmation(false);
  };

  const handleLayananChange = (e) => {
    const selectedLayanan = e.target.value;
    setLayanan(selectedLayanan);


    setBidangOptions({
      'Aktivitas Hukum': ['Hukum Pidana', 'Hukum Perdata', 'Hukum Sengketa Tata Usaha Negara', 'Perselisihan Tenaga Kerja', 'Legal Audit', 'Kepailitan', 'Perusahaan Perbankan', 'Kontrak', 'Kekayaan Intelektual'],
      'Aktivitas Konsultan Hukum': ['Persiapan dokumen hukum', 'Dokumen badan hukum', 'Perjanjian kerjasama atau pembentukan perusahaan', 'Penyiapan akta notaris', 'Surat Wasiat'],
      'Aktivitas Kekayaan Intelektual': ['Surat Wasiat', 'Hak cipta', 'Merk', 'Indikasi geografis', 'Desain industri', 'Desain tata letak sirkuit terpadu serta rahasia dagang'],
      'Aktivitas Konsultan Pajak': ['Penyiapan pengembalian pajak pendapatan usaha atau perorangan termasuk jasa penyiapan bantuan nasihat dan perwakilan atas nama klien dihadapan petugas pajak'],
    }[selectedLayanan] || []);

    if (!selectedLayanan) {
      setBidang('');
    }
  };

  const saveForm = async (e) => {
    e.preventDefault();

    const errors = {};

    if (!nama.trim()) {
      errors.nama = 'Nama harus diisi!';
    }

    if (!email.trim()) {
      errors.email = 'Email harus diisi!';
    }

    if (!no_telepon.trim()) {
      errors.no_telepon = 'No Telepon harus diisi!';
    }

    if (!layanan) {
      errors.layanan = 'Layanan harus dipilih!';
    }

    if (!bidang) {
      errors.bidang = 'Bidang harus dipilih!';
    }

    if (!kronologi.trim()) {
      errors.kronologi = 'Kronologi harus diisi!';
    }

    setNamaError(errors.nama || '');
    setEmailError(errors.email || '');
    setNoTeleponError(errors.no_telepon || '');
    setLayananError(errors.layanan || '');
    setBidangError(errors.bidang || '');
    setKronologiError(errors.kronologi || '');

    if (Object.values(errors).some(error => error)) {
      return;
    }

    showConfirmationPrompt();
  };

  const handleConfirmation = async (confirmed) => {
    hideConfirmationPrompt();

    if (confirmed) {
      const formData = new FormData();
      formData.append('nama', nama);
      formData.append('email', email);
      formData.append('no_telepon', no_telepon);
      formData.append('layanan', layanan);
      formData.append('bidang', bidang);
      formData.append('kronologi', kronologi);

      try {
        await axios.post('https://admin.aedax-lawfirm.com/api/forms', formData, {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        });
        navigate('/');
      } catch (error) {
        console.log(error);
      }
    } else {
    }
  };

  return (
    <div className='mx-auto'>
      <form onSubmit={saveForm}>
        <div className='mt-8 text-center font-normal text-3xl md:text-4xl font-serif'>
          <h1>Formulir</h1>
        </div>
        <div className='flex justify-center mt-2'>
          <img src={line} alt="" width={234.01} />
        </div>
        <div className='mt-8 md:flex md:justify-center md:space-x-10 mx-auto md:px-60'>
          <div className='md:mx-auto w-full'>
            <div className='w-full px-4 py-2 md:p-0 md:justify-center'>
              <span className='font-serif text-[#999999]'>Nama
                <span style={{ color: '#FF0404' }}>*</span>:
              </span>
              <input
                className={`px-2 py-2 border-2 mt-2 w-full bg-[#D9D9D9] hover-[#D9D9D9] rounded-md ${namaError ? 'border-red-500' : ''
                  }`}
                type="text"
                value={nama}
                onChange={(e) => setNama(e.target.value)}
              />
              {namaError && <p className='text-red-500'>{namaError}</p>}
            </div>
          </div>
          <div className='md:mx-auto w-full '>
            <div className='w-full px-4 py-2 md:p-0 md:justify-center'>
              <span className='font-serif text-[#999999]'>Email
                <span style={{ color: '#FF0404' }}>*</span>:
              </span>
              <input
                className={`px-2 py-2 border-2 mt-2 w-full bg-[#D9D9D9] hover-[#D9D9D9] rounded-md ${emailError ? 'border-red-500' : ''
                  }`}
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && <p className='text-red-500'>{emailError}</p>}
            </div>
          </div>
        </div>

        <div className='flex justify-center mt-4 px-4 py-2 md:p-0'>
          <div className='mx-auto w-full md:px-60'>
            <span className='font-serif text-[#999999]'>No Telepon
              <span style={{ color: '#FF0404' }}>*</span>:
            </span>
            <input className={`px-2 py-2 border-2 mt-2 w-full bg-[#D9D9D9] hover-[#D9D9D9] rounded-md ${noTeleponError ? 'border-red-500' : ''
              }`}
              type="text"
              value={no_telepon}
              onChange={(e) => setNo_telepon(e.target.value)} />
            {noTeleponError && <p className='text-red-500'>{noTeleponError}</p>}
          </div>
        </div>
        <div className='flex justify-center mt-4 px-4 py-2 md:p-0'>
          <div className='mx-auto w-full md:px-60'>
            <span className='font-serif text-[#999999]'>Layanan
              <span style={{ color: '#FF0404' }}>*</span>:
            </span>

            <div className='relative'>
              <select
                className={`px-4 py-2 border-2 mt-2 w-full bg-[#D9D9D9] hover-[#D9D9D9] appearance-none rounded-md ${layananError ? 'border-red-500' : ''}`}
                value={layanan}
                onChange={handleLayananChange}
              >
                <option value='' disabled>Pilih Layanan</option>
                <option value='Aktivitas Hukum'>Aktivitas Hukum</option>
                <option value='Aktivitas Konsultan Hukum'>Aktivitas Konsultan Hukum</option>
                <option value='Aktivitas Kekayaan Intelektual'>Aktivitas Kekayaan Intelektual</option>
                <option value='Aktivitas Konsultan Pajak'>Aktivitas Konsultan Pajak</option>
              </select>
              <img
                src={dropdownArrow}
                alt='Dropdown Arrow'
                className='absolute top-1/2 right-2 transform -translate-y-1/3 pointer-events-none'
                style={{ width: '20px', height: '20px' }}
              />
            </div>
            {layananError && <p className='text-red-500'>{layananError}</p>}
          </div>
        </div>
        <div className='flex justify-center mt-4 px-4 py-2 md:p-0'>
          <div className='mx-auto w-full md:px-60'>
            <span className='font-serif text-[#999999]'>Bidang
              <span style={{ color: '#FF0404' }}>*</span>:
            </span>
            <div className='relative'>
              <select
                className={`px-4 py-2 border-2 mt-2 w-full bg-[#D9D9D9] hover-[#D9D9D9] appearance-none rounded-md ${bidangError ? 'border-red-500' : ''}`}
                value={bidang}
                onChange={(e) => setBidang(e.target.value)}
              >
                <option value=''>Pilih Bidang</option>
                {bidangOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <img
                src={dropdownArrow}
                alt='Dropdown Arrow'
                className='absolute top-1/2 right-2 transform -translate-y-1/3 pointer-events-none'
                style={{ width: '20px', height: '20px' }}
              />
            </div>
            {bidangError && <p className='text-red-500'>{bidangError}</p>}
          </div>
        </div>
        <div className='flex justify-center mt-4 px-4 py-2 md:p-0'>
          <div className='mx-auto w-full md:px-60'>
            <span className='font-serif text-[#999999]'>Kronologis
              <span style={{ color: '#FF0404' }}>*</span>:
            </span>
            <textarea className={`h-44 px-2 py-2 border-2 mt-2 w-full bg-[#D9D9D9] hover-[#D9D9D9] rounded-md ${kronologiError ? 'border-red-500' : ''
              }`}
              type="text"
              value={kronologi}
              onChange={(e) => setKronologi(e.target.value)} />
            {kronologiError && <p className='text-red-500'>{kronologiError}</p>}
          </div>
        </div>

        <div className='flex justify-center p-24'>
          <button className='bg-[#B7926E] text-white font-serif px-28 py-3 font-bold' onClick={saveForm}>
            Kirim
          </button>
        </div>
      </form>

      {showConfirmation && (
        <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center'>
          <div className='bg-white p-8 rounded-md'>
            <p className='text-xl font-semibold mb-4'>Anda yakin ingin mengirim formulir?</p>
            <div className='flex justify-end'>
              <button className='bg-[#B7926E] text-white font-serif px-6 py-2 mr-4' onClick={() => handleConfirmation(true)}>
                Ya
              </button>
              <button className='bg-[#E5E7EB] text-gray-800 font-serif px-6 py-2' onClick={() => handleConfirmation(false)}>
                Tidak
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Form;
