import React from 'react'
import Form from '../components/Form'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

export const Formulir = () => {
  return (
    <>
      <div>
        <div className='fixed top-0 left-0 w-full z-50 bg-white'>
          <Navbar />
        </div>
        <div className='mt-32 md:mt-36'>
          <Form />
        </div>
        <Footer />
      </div>
    </>
  )
}
export default Formulir
