import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import AktivitasKekayaanIntelektual from '../components/assets/AktivitasKekayaanIntelektual'

const LayananHukum = () => {
  return (
    <>
    <div>
        <Navbar/>
        <AktivitasKekayaanIntelektual/>
        <div className='mt-32'>
        <Footer/>
        </div>
    </div>
    </>
  )
}

export default LayananHukum