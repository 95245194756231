export const content = {
    AktivitasHukum: {
        title: "Aktivitas Hukum",
        subtitle: "Mengidentifikasi Fakta dan Masalah Aktivitas Hukum :",
        body1: "1. Hukum Pidana", 
        body2: "2. Hukum Perdata",
        body3: "3. Hukum Tata Usaha Negara",
        body4: "4. Perselisihan Tenaga Kerja",
        body5: "5. Legal Audit", 
        body6: "6. Kepailitan", 
        body7: "7. Perusahaan Perbankan",
        body8: "8. Kontrak",
        body9: "9. Kekayaan Intelektual"
    },
    AktivitasKonsultanHukum : {
        title: "Aktivitas Konsultan Hukum",
        subtitle: "Mengidentifikasi Fakta dan Masalah Aktivitas Hukum :",
        body1: "1. Persiapan Dokumen Hukum", 
        body2: "2. Dokumen Badan Hukum",
        body3: "3. Perjanjian Kerjasama atau Pembentukan Perusahaan",
        body4: "4. Penyiapan Akta Notaris",
        body5: "5. Surat Wasiat", 
    },
    AktivitasKekayaanIntelektual : {
        title: "Aktivitas Kekayaan Intelektual",
        subtitle: "Mengidentifikasi Fakta dan Masalah Aktivitas Kekayaan Intelektual :",
        body1: "Pengajuan dan pengurusan dibidang kekayaan intelektual (hak paten, hak cipta, merk, indikasi geografis, desain industri, desain tata letak sirkuit terpadu serta rahasia dagang."
    },

    AktivitasKonsultanPajak : {
        title: "Aktivitas Konsultan Pajak",
        subtitle: "Mengidentifikasi Fakta dan Masalah Aktivitas Konsultan Pajak :",
        body1: "Jasa Konsultasi seperti penyiapan pengembalian pajak pendapatan usaha atau perorangan termasuk jasa penyiapan bantuan nasihat dan perwakilan atas nama klien dihadapan petugas pajak."
    },
    
};
