import React from 'react'
import Service from '../components/Service'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const Layanan = () => {
  return (
    <>
    <div>
        <Navbar/>
    </div>
    <div>
        <Service/>
    </div>
    <div className=''>
        <Footer/>
    </div>
    </>
  )
}

export default Layanan